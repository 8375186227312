<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>
<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full">
  @if (!backService.noHeader) {
    <div
      class="relative flex flex-row flex-0 justify-between py-2.5 px-6 bg-white border-b dark:bg-gray-700"
    >
      <!-- Logo/back button -->
      <div class="grid content-center logo" [style.viewTransitionName]="'betterplan-logo-mobile'">
        @if (isFirstStep !== undefined && isFirstStep) {
          <a [routerLink]="'/dashboard'" [attr.data-cy]="'bps-img-btn'" @fadeIn>
            @if (appConfig.scheme === 'dark') {
              <img
                src="assets/img/auxi/logo-betterplan-white.svg"
                class="hidden w-auto h-7 sm:block"
                alt="Betterplan Advisors"
                title="Betterplan Advisors"
              />
            }
            @if (appConfig.scheme === 'dark') {
              <img
                src="assets/img/auxi/betterplan-b-light.svg"
                class="block w-auto h-7 sm:hidden"
                alt="Betterplan Advisors"
                title="Betterplan Advisors"
              />
            }
            @if (appConfig.scheme === 'light') {
              <img
                src="assets/img/auxi/logo-full.svg"
                class="hidden w-auto h-7 sm:block"
                alt="Betterplan Advisors"
                title="Betterplan Advisors"
              />
            }
            @if (appConfig.scheme === 'light') {
              <img
                src="assets/img/logo-loading.svg"
                class="block w-auto h-7 sm:hidden"
                alt="Betterplan Advisors"
                title="Betterplan Advisors"
              />
            }
          </a>
        }
        @if (isFirstStep !== undefined && !isFirstStep) {
          <button
            class="max-h-7"
            mat-icon-button
            (click)="back()"
            @fadeIn
            [attr.data-cy]="'bps-back-btn'"
          >
            <mat-icon
              class="icon-size-5 text-accent"
              [svgIcon]="'heroicons_solid:arrow-left'"
            ></mat-icon>
          </button>
        }
      </div>
      <!-- Steps -->
      <div class="flex items-center mt-0 shrink-0 sm:ml-4">
        <div class="flex-1 ml-5 text-right steps-nums justify-self-start" @fadeIn>
          <a (click)="close()" mat-icon-button @fadeIn [attr.data-cy]="'bps-close-btn'">
            <mat-icon
              class="icon-size-5 text-accent"
              [svgIcon]="'heroicons_solid:x-mark'"
            ></mat-icon>
          </a>
        </div>
      </div>
    </div>
  }
  <!-- Content -->
  <div class="flex flex-col flex-auto">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
  Otherwise, layout changes won't be registered and the view won't be updated! -->
    @if (true) {
      <router-outlet></router-outlet>
    }
  </div>
</div>
