import { ErrorHandler, Injectable } from '@angular/core';
import LogRocket from 'logrocket';

@Injectable()
export class LogRocketErrorHandler implements ErrorHandler {
  handleError(error: any) {
    LogRocket.captureException(error);
    console.error(error);
  }
}
